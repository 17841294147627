<script>
import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";
import Avatar from "../../../components/elements/user/avatar.vue";

export default {
    components: {
        Avatar,
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            dynamicFields: 'partner/fields',
            authenticated: 'user/authenticated',
            admin: 'auth/admin'
        }),

        fields: function () {
            const fields = [
                {
                    key: "code",
                    label: this.$t('partners::partners.columns.code').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "name",
                    label: this.$t('partners::partners.columns.name').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "users",
                    label: this.$t('partners::partners.relationships.users').ucFirst(),
                    formatter: (value) => value ? value.map(user => user.name).join(', ') : null,
                    thClass: '',
                    sortable: true
                },
            ];

            if (this.admin) {
                fields.push({
                        key: "registration_number",
                        label: this.$t('partners::partners.columns.registration_number').ucFirst(),
                        thClass: '',
                        sortable: true
                    },
                    {
                        key: "vat_number",
                        label: this.$t('partners::partners.columns.vat_number').ucFirst(),
                        thClass: '',
                        sortable: true
                    },
                    {
                        key: "email",
                        label: this.$t('partners::partners.columns.email').ucFirst(),
                        thClass: '',
                        sortable: true
                    },
                    {
                        key: "phone",
                        label: this.$t('partners::partners.columns.phone').ucFirst(),
                        thClass: '',
                        sortable: true
                    },)
            }

            if (this.dynamicFields && this.dynamicFields.length) {
                const dynamicFields = this.dynamicFields.filter(field => field && field.options && field.options.display_as_table_column);

                dynamicFields.forEach((field) => {
                    fields.push(
                        {
                            key: field.transformed_name,
                            label: field.translation_key ? this.$t(field.translation_key).ucFirst() : field.name,
                            thClass: '',
                            sortable: false
                        },
                    )
                });
            }

            fields.push(
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                }
            )

            return fields;
        },

        createLink: function () {
            return { name: 'accessible-partner-create', params: {type: this.$t('partners::partners.types.' + this.type)} };
        }
    },

    data() {
        return {};
    },

    created() {
        this.$store.dispatch('partner/fields', { owner_type: 'Appon\\Partners\\Models\\Partner', with: 'fieldType' })
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'partners::partners.partner', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        autocomplete="partner"
        :caption="$tc('partners::partners.partner', 2).ucFirst()"
        :current-page="1"
        :edit-link="{ name: 'partner-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :per-page="10"
        :queries="{ type: type, with: ['users', 'users.image', 'users.gender'] }"
        borderless
        editable
        hover
        action="accessible"
        moduleNamespace="partner"
        module-pager="accessiblePager"
        outlined
        permission="partners"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="name"
        sort-direction="asc"
        sort-icon-left
    >
        <template #users="{value}">
            <div class="avatar-group">
                <div v-for="(user, index) in value" :key="index" class="avatar-group-item">
                    <avatar class="opacity-50 opacity-hover-100" :user="user" size="sm"></avatar>
                </div>
            </div>
        </template>
        <template #actions="{ item, methods }">
            <li class="list-inline-item">
                <router-link
                    :to="methods.getEditLink(item.id)"
                    :title="$t('base.edit').ucFirst()"
                    class="px-2 text-primary"
                >
                    <i class="bx bx-pencil font-size-18"></i>
                </router-link>
            </li>
        </template>
        <template #extra-actions="{ item }">
                <span :id="item.name" class="dropdown-item cursor-pointer">
                    <i class="mdi mdi-plus-box-multiple-outline font-size-14 me-2"></i>
                    <span>Extra action</span>
                </span>
        </template>
    </advanced-table>
</template>
